var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mobile-views-validate-po-details-list" },
    _vm._l(_vm.items, function (item, i) {
      return _c(
        "div",
        { key: i, staticClass: "card", on: { click: _vm.handleCardClick } },
        [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "head" }, [
              _c("h6", [_vm._v(_vm._s(item.name))]),
            ]),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "info" }, [
                _c("span", [
                  _vm._v(
                    "\n\t\t\t\t\t\t" + _vm._s(_vm.FormMSG(3, "Category")) + ": "
                  ),
                  _c("b", [_vm._v(_vm._s(item.categoryName))]),
                ]),
                _c("br"),
                _c("span", [
                  _vm._v(
                    "\n\t\t\t\t\t\t" +
                      _vm._s(_vm.FormMSG(4, "Description")) +
                      ": "
                  ),
                  _c("b", [_vm._v(_vm._s(item.description))]),
                ]),
              ]),
              _c("div", { staticClass: "price" }, [
                _c("span", [
                  _vm._v(
                    "\n\t\t\t\t\t\t" + _vm._s(_vm.FormMSG(1, "Price:")) + ": "
                  ),
                  _c("b", [_vm._v(_vm._s(_vm.rendPrice(item.price)))]),
                ]),
                _c("br"),
                _c("span", [
                  _vm._v(
                    "\n\t\t\t\t\t\t" + _vm._s(_vm.FormMSG(2, "Total:")) + ": "
                  ),
                  _c("b", [_vm._v(_vm._s(_vm.rendTotal(item.total)))]),
                ]),
              ]),
            ]),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }